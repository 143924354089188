<template>
  <v-container fluid>
    <base-material-card>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-btn
            outlined
            dense
            color="primary"
            @click.native="$router.back()"
            style="height: 31px; width: 200px"
            class="mt-1"
            v-if="!$vuetify.breakpoint.xsOnly"
          >
            <v-icon class="mt-0 pr-2" style="margin-left: -7px"
              >mdi-arrow-left</v-icon
            >
            <span class="">
              {{ $t("boxes.back") }}
            </span>
          </v-btn>
          <BoxCard style="margin-top: 15px" @popup="newBox1 = true" :box="box">
          </BoxCard>
        </v-col>
        <v-col cols="12" md="6">
          <v-row style="width: 120%"
            ><v-col cols="4" class="py-0" id="time">
              <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    data-vv-name="startDate"
                    :label="$t('events.date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="date ? $d(new Date(date), 'date2digits') : ''"
                    ><template v-slot:prepend-inner>
                      <!--Aqui va el v-icon -->
                      <v-icon
                        small
                        class="pt-0 pb-1"
                        style="margin-top: 5px"
                        color="primary"
                        >$appointments</v-icon
                      >
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="date"
                  no-title
                  scrollable
                  color="primary"
                  @input="
                    menu = false;
                    fetchTable();
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="pl-0"> </v-col>
          </v-row>
          <v-row
            ><v-col class="pt-0" cols="12">
              <v-simple-table id="table2">
                <tr>
                  <th colspan="1"></th>
                  <th colspan="1"></th>

                  <th
                    style="
                      text-transform: uppercase;
                      text-decoration: underline;
                    "
                  >
                    {{ table.name }}
                  </th>
                </tr>
                <tr>
                  <th colspan="1"></th>
                  <th colspan="1"></th>
                  <th>
                    <div
                      class="mb-1"
                      @click="
                        $router.push({
                          name: 'tattooerView',
                          params: { id: table.tattooer.id },
                        })
                      "
                      style="display: flex; justify-content: center"
                      v-if="table.tattooer_id != null"
                    >
                      <div
                        class="rounded-pill pt-0"
                        :style="`
                        border: 2px solid var(--v-primary-base);
                        height: 30px;
                        width: 50%;
                        line-height: 25px; 
                        background-color:${table.tattooer.color};
                        cursor: pointer;`"
                      >
                        {{ table.tattooer.user.first_name }}
                        {{ table.tattooer.user.last_name }}
                      </div>
                    </div>
                    <p v-else></p>
                  </th>
                </tr>
                <tr :key="h" v-for="(h, i) in hoursRow">
                  <td
                    v-if="hoursRow.length > i + 1"
                    :style="
                      h.indexOf(':00') === -1
                        ? 'color: transparent; border-top:0px'
                        : 'position: relative'
                    "
                  >
                    <span>{{ h }}</span>
                  </td>

                  <td v-else style="position: relative">
                    <span
                      >{{ +hoursRow[i - 1].split(":")[0] + 1 }}:{{
                        hoursRow[i - 1].split(":")[1]
                      }}</span
                    >
                  </td>
                  <td
                    colspan="1"
                    width="1px"
                    :style="h.indexOf(':00') === -1 ? ' border-top:0px' : ''"
                  ></td>
                  <template>
                    <td
                      v-if="tattooerHours(h).reserva"
                      :rowspan="tattooerHours(h).colspan"
                      :style="`border: 2px solid var(--v-primary-base); background-color:${
                        tattooerHours(h).color
                      }`"
                    >
                      {{ tattooerHours(h).reserva.tattooer.user.first_name }}
                      {{ tattooerHours(h).reserva.tattooer.user.last_name }}
                    </td>
                    <td
                      v-else-if="rowspan(h)"
                      @click="reserva(h)"
                      :style="h.indexOf(':00') === -1 ? ' border-top:0px' : ''"
                      v-bind:class="[{ gris2: checkTime(h, date) }]"
                    ></td>
                  </template>
                </tr>
              </v-simple-table> </v-col
          ></v-row>
        </v-col>
      </v-row>
      <v-dialog max-width="300" v-model="newBox1">
        <v-row class="ma-0">
          <v-card class="popupCard"
            ><v-card-title
              style="text-decoration: underline; text-transform: uppercase"
            >
              {{ $t("boxes.new_box") }}
            </v-card-title>
            <div class="close">
              <v-btn icon @click="newBox1 = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <base-material-card class="base">
              <form
                action
                data-vv-scope="box-form"
                @submit.prevent="validateForm('box-form')"
              >
                <v-row class="pt-0 pb-0" style="width: 73%; margin: 0 auto">
                  <v-col cols="12" class="pt-1 pb-0">
                    <label style="font-size: 12px">{{
                      $t("boxes.name")
                    }}</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="box.name"
                      v-validate="'required|min:1'"
                      :error-messages="errors.collect('box-form.name')"
                      color="secondary"
                      data-vv-validate-on="blur"
                      hide-details
                      data-vv-name="name"
                    />
                  </v-col>
                  <v-col class="pt-0 pb-2" cols="12">
                    <label style="font-size: 12px">{{
                      $t("boxes.price")
                    }}</label>

                    <number-input-with-separators
                      outlined
                      dense
                      hide-details
                      :value="box"
                      valueKey="price"
                      rules="required|is_not:0€"
                      ref="money_1"
                    ></number-input-with-separators>
                  </v-col>
                  <v-col class="pt-0 pb-5" cols="12">
                    <label style="font-size: 12px">{{
                      $t("boxes.token_payment")
                    }}</label>

                    <number-input-with-separators
                      outlined
                      dense
                      hide-details
                      :value="box"
                      valueKey="token_payment"
                      rules="required|is_not:0€"
                      ref="money_2"
                    ></number-input-with-separators>
                  </v-col>
                </v-row>

                <v-row
                  class="pt-3 pl-5 pb-0"
                  justify="center"
                  style="margin-bottom: 10px"
                >
                  <v-btn
                    class="mr-3"
                    rounded
                    outlined
                    style="height: 30px; width: 100px"
                    color="primary"
                    @click="(newBox1 = false), (item2 = false)"
                    >{{ $t("cancel") }}</v-btn
                  >
                  <v-btn
                    class="mr-3"
                    rounded
                    elevation="0"
                    style="height: 30px; width: 100px !important"
                    color="primary"
                    type="submit"
                    ><v-icon style="margin-right: 5px" size="14px">$save</v-icon
                    >{{ $t("save", { name: $t("") }) }}</v-btn
                  >
                </v-row>
              </form>
            </base-material-card>
          </v-card>
        </v-row>
      </v-dialog>

      <v-dialog max-width="500" v-model="dialog">
        <v-row class="ma-0">
          <v-card class="popupCard"
            ><v-card-title
              style="text-decoration: underline; text-transform: uppercase"
            >
              {{ $t("boxes.reservation") }}
            </v-card-title>
            <div class="close">
              <v-btn icon @click="dialog = false" small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <base-material-card class="base">
              <form
                action
                data-vv-scope="box-form"
                @submit.prevent="validateForm('box-form')"
              >
                <v-row
                  ><v-col cols="6" class="pl-6"
                    ><label v-text="$t('boxes.tattooer')" />
                    <v-select
                      :items="tattooers"
                      v-model="res.tattooer"
                      single-line
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      data-vv-validate-on="blur"
                      v-validate="'required'"
                      style="display: inline-block; width: 100%"
                      ><template slot="selection" slot-scope="data">
                        {{ data.item.user.first_name }}
                        {{ data.item.user.last_name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.user.first_name }}
                        {{ data.item.user.last_name }}
                      </template></v-select
                    ></v-col
                  ><v-col cols="6" class="pr-6"
                    ><label v-text="$t('box')" />
                    <v-select
                      :items="boxes"
                      v-model="res.id"
                      single-line
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      data-vv-validate-on="blur"
                      v-validate="'required'"
                      style="display: inline-block; width: 100%"
                      ><template slot="selection" slot-scope="data">
                        {{ data.item.name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.name }}
                      </template></v-select
                    ></v-col
                  >
                </v-row>

                <v-row style="width: 100%; margin: 0 auto">
                  <v-col
                    id="time"
                    cols="12"
                    md="3"
                    lg="5"
                    class="py-md-3 pb-0 pt-2"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      transition="scale-transition"
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          :value="date ? $d(new Date(date), 'date2digits') : ''"
                          hide-details
                          dense
                          data-vv-validate-on="blur"
                          v-validate="'required'"
                          data-vv-name="startDate"
                          :label="$t('events.date')"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          ><template v-slot:prepend-inner>
                            <!--Aqui va el v-icon -->
                            <v-icon
                              small
                              class="pt-0 pb-1"
                              style="margin-top: 5px"
                              color="primary"
                              >$appointments</v-icon
                            >
                          </template></v-text-field
                        >
                      </template>
                      <v-date-picker
                        first-day-of-week="1"
                        v-model="date"
                        no-title
                        scrollable
                        color="primary"
                        :min="date"
                        @input="
                          menu2 = false;
                          fetchTable();
                        "
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-select
                      :items="hoursRow"
                      v-model="res.start"
                      :label="$t('events.startTime')"
                      dense
                      outlined
                      hide-details
                      data-vv-validate-on="blur"
                      v-validate="'required'"
                      data-vv-name="startTime"
                      style="display: inline-block; width: 45%"
                    ></v-select>
                    <p
                      class="pa-1"
                      style="
                        display: inline-block;
                        width: 10%;
                        text-align: center;
                        color: var(--v-primary-base);
                      "
                    >
                      -
                    </p>
                    <v-select
                      :items="hoursRow"
                      v-model="res.end"
                      :label="$t('events.endTime')"
                      hide-details
                      dense
                      data-vv-validate-on="blur"
                      v-validate="'required'"
                      data-vv-name="endTime"
                      outlined
                      style="display: inline-block; width: 45%"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row
                  style="width: 100%; margin: 0 auto"
                  class="pt-3 mb-3 pr-3"
                  justify="end"
                >
                  <v-btn
                    outlined
                    @click="dialog = false"
                    class="mr-3"
                    style="height: 30px; width: 100px"
                    elevation="0"
                    >{{ $t("cancel") }}</v-btn
                  >
                  <v-btn
                    @click="reservation()"
                    elevation="0"
                    style="height: 30px; width: 100px !important"
                    ><v-icon small style="margin-right: 5px">$save</v-icon
                    >{{ $t("save", { name: "" }) }}</v-btn
                  >
                </v-row>
              </form>
            </base-material-card>
          </v-card>
        </v-row>
      </v-dialog>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "BoxView",
  data() {
    return {
      menu: false,
      menu2: false,
      newBox1: false,
      id_box: null,
      date: new Date().toISOString().substr(0, 10),
      box: {},
      items: [],
      table: {},
      options: {},
      totalPrice: 0,
      num: null,
      count: 0,
      dialog: false,
      tattooers: [],
      boxes: [],
      filters: {
        dates: "",
      },
      res: {
        id: null,

        start: null,
        end: null,
        tattooer: null,
      },
      timeStudio: [],
    };
  },
  components: {
    BoxCard: () => import("@/components/boxes/Card"),
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  watch: {
    options: {
      handler() {
        // this.fetchAppointments();
      },
      deep: true,
    },
  },
  computed: {
    timeFrom() {
      if (this.timeStudio.length > 0) {
        let min = "23:00";
        let minFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[0];
            if (h <= min) {
              min = h;
            }
          });
        });
        return min;
      }
    },
    timeTo() {
      if (this.timeStudio.length > 0) {
        let max = "00:00";
        let maxFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[1];
            if (h >= max) {
              max = h;
            }
          });
        });
        return max;
      }
    },
    hours() {
      if (this.timeStudio.length > 0) {
        let mins = [":00", ":15", ":30", ":45"];
        let hours = [];
        let first = parseInt(this.timeFrom.split(":")[0]);
        let last = parseInt(this.timeTo.split(":")[0]);
        for (let x = first; x < last; x++) {
          mins.forEach((y) => {
            if (x < 10) hours.push("0" + x + y);
            else hours.push(x + y);
          });
        }
        //console.log(hours);
        hours.push(last + ":00");
        if (this.timeTo.split(":")[1] == "30") hours.push(last + ":30");
        return hours;
      }
    },
    hoursRow() {
      if (this.timeStudio.length > 0) {
        let mins = [":00", ":15", ":30", ":45"];
        let hours = [];
        let first = parseInt(this.timeFrom.split(":")[0]);
        let last = parseInt(this.timeTo.split(":")[0]);
        for (let x = first; x < last; x++) {
          mins.forEach((y) => {
            if (x < 10) hours.push("0" + x + y);
            else hours.push(x + y);
          });
        }
        return hours;
      }
    },
  },
  mounted() {
    this.date = new Date().toISOString().substr(0, 10);
    this.fetchBoxes();
    this.fetchTattooers();
    this.fetchTable();
    this.fetchTimeStudio();
  },

  methods: {
    checkTime(h, day) {
      let dh = this.timeStudio[this.dayOfWeek(day)];
      let error = true;
      dh.forEach((ha) => {
        if (ha[0] <= h && ha[1] > h) error = false;
      });
      return error;
    },

    dayOfWeek(date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    },
    ...mapActions("boxes", ["getBox", "updateBox", "getTableBox"]),
    ...mapActions("studios", ["getActiveTattooer", "getStudio"]),
    ...mapActions("tattooers", ["reservationBox"]),
    fetchBoxes() {
      this.loading = true;
      this.getBox(this.$route.params.id).then((box) => {
        //console.log(box);
        this.box = box;
        this.boxes = box;
      });
    },

    fetchTimeStudio() {
      this.getStudio(this.$store.state.auth.user.studio.id).then((data) => {
        //console.log(data);
        this.timeStudio = data.timetable;
        //console.log(this.timeStudio);
      });
    },
    fetchTattooers() {
      this.loading = true;
      this.getActiveTattooer({}).then((data) => {
        this.tattooers = data.map((x) => x.tattooer);
        //console.log(this.tattooers);
        this.loading = false;
      });
    },
    fetchTable() {
      this.loading = true;

      //console.log("%c%s", "color: #733d00", "date", this.$route.params.id);
      this.getTableBox({ id_box: this.$route.params.id, date: this.date }).then(
        (boxes) => {
          //console.log(boxes.box);

          this.table = boxes.box;
          //console.log(this.table);
          this.loading = false;
        }
      );
    },
    async validateForm(scope) {
      this.errors.clear();
      //console.log(scope);

      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.update();
          this.newBox1 = false;
          this.box.price = null;
          this.box.name = null;
          this.box.token_payment = null;
        }
      });
    },
    reserva(h) {
      this.dialog = true;
      this.res.start = h;
      this.res.tattooer = null;
      this.res.end = null;

      this.res.id = this.box.id;

      //console.log(this.res);
    },
    update() {
      //console.log(this.box);
      this.updateBox({
        box: this.box,
        id_box: this.box.id,
      }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("box_edit_success"), "", "");
          this.fetchBoxes();
          this.fetchTable();
        }
      });
    },
    reservation() {
      this.res.date = this.date;
      //console.log(this.box);
      this.reservationBox({
        tattooer_id: this.res.tattooer,
        formData: this.res,
      }).then((data) => {
        //console.log(data);
        if (data.count === 0) {
          this.$alert("Guardado correctamente", "", "");
          this.fetchBoxes();
          this.fetchTable();
          this.dialog = false;
          this.$emit("update");
        } else {
          console.log(data);
          if (data.sentencia)
            this.$alert("El artista asignado está ocupado en este horario");
          else this.$alert("No hay disponibilidad para esta fecha", "", "");
        }
      });
    },
    tattooerHours(h) {
      ////console.log(hs);
      let hs = this.table.boxes_tattooer.find((x) => x.start_time == h);
      if (hs) {
        let mins = [":00", ":15", ":30", ":45"];
        let hours = [];
        let end = parseInt(this.timeTo);
        let start = parseInt(this.timeFrom);
        for (let x = start; x < end; x++) {
          mins.forEach((y) => {
            if (x < 10) hours.push("0" + x + y);
            else hours.push(x + y);
          });
        }
        let span = 0;
        let s = 0;

        for (let i = 0; i < hours.length; i++) {
          if (hours[i] == hs.start_time) {
            s = i;
          } else if (hours[i] == hs.end_time) {
            span = i - s;
          }
        }

        return { reserva: hs, colspan: span, color: hs.tattooer.color };
      }

      return { reserva: null, colspan: 1 };
    },
    rowspan(h) {
      let hs = this.table.boxes_tattooer.find((x) => {
        return h > x.start_time && h < x.end_time;
      });
      ////console.log(n.boxes_tattooer[0].start_time == h);

      if (hs) {
        return false;
      }

      return true;
    },
  },
};
</script>


<style scoped lang="sass">
#table2
  background-color: var(--v-gris3-base)
  tr:first-child th
    border: 0 !important
    font-size: 16px !important
  tr:nth-child(2) th
    border: 0
  td:first-child, th:first-child
    border-left: 0
    border-top: 0
    min-width: 4px
  tr:last-child td:first-child
    border-bottom: 0
    span
      margin-top: -12px !important
  td
    height: 12px

  span
    display: block
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  td:first-child span
    position: absolute
    margin-top: -15px

  tr, td:first-child, th:first-child
    border-left: 0
    border-right: 0
    color: white

  tr:last-child td
    border-bottom: 1px solid var(--v-primary-base)
  td, th
    border-top: 1px solid var(--v-primary-base)
    border-right: 1px solid var(--v-primary-base)

    border-color: var(--v-primary-base) !important
    padding: 0 !important

    min-width: 100px
    max-width: 100px
    text-align: center
  th
    padding: 4px !important
  td:nth-child(2), th:nth-child(2)
    min-width: 20px !important
    width: 20px
    border-left: 0
    border-bottom: 0
    border-top: 1px solid var(--v-primary-base)
.popupCard

  color: red !important
  width: 100% !important
  background-color: var(--v-gris3-base) !important
  overflow: hidden
  .close .v-btn
    top: 0
    right: 0px
    position: absolute

    .v-icon
      color: var(--v-primary-base) !important
      position: absolute

  .v-card__title
    background-color: var(--v-gris3-base)
  .v-text-field__details
    display: none
#time
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer
    margin-top: -8px !important
h2
  font-weight: normal
  font-size: 20px
.base
  .v-input__control, .row .v-btn
    height: 30px !important
    margin-top: 5px

  label
    display: block
    height: 20px
.v-data-table
td
  padding: 3px 8px !important
td:first-child
  color: var(--v-gris1-base)
</style>